/* eslint-disable complexity, sonarjs/cognitive-complexity */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SHOW_PRICE_TYPES } from '../../../config/constants/deals';
import { SPLAT_MAX_WIDTH_PX } from '../../../config/setup/setup';
import {
  showPriceType,
  showPrice,
  showPerNight,
  splatPriceABTest,
  getPrice,
  splatPriceDisplayHideNonVip,
} from '../../../helpers/deals';
import useClientSideEffect from '../../../helpers/useClientSideEffect';
import ThemeContext from '../../../providers/ThemeProvider';

const VipDealPriceSplat = ({
  className,
  coinFlipAnimation = false,
  deal = {},
  discount = false,
  displayDiscountAndOriginalPrice = false,
  displayEstimatedText = false,
  estimatedText = null,
  forcePrice = true,
  isHideIfNoDiscount = true,
  isHideSplat = false,
  visible = true,
}) => {
  const theme = useContext(ThemeContext);
  const priceContainer = useRef();
  const [shrinkPrice, setShrinkPrice] = useState(false);
  const isMarketingCreditDeal = deal.display?.vipDiscountAllowed;
  const isVipExclusiveDeal = deal.display?.vipExclusive;

  useClientSideEffect(() => {
    setShrinkPrice(priceContainer?.current?.offsetWidth > SPLAT_MAX_WIDTH_PX);
  }, [priceContainer]);

  const priceType = showPriceType(
    deal,
    discount,
    forcePrice,
    isHideIfNoDiscount,
  );

  const defaultPrice = getPrice({
    columnSelection: null,
    deal,
    displayDiscountAndOriginalPrice,
    forceDecimals: true,
    isVip: true,
    priceType,
    rowSelection: null,
  });

  // hide splat
  if (priceType === SHOW_PRICE_TYPES.hidden || isHideSplat) return null;

  return (
    <div
      className={classNames('splat', className, {
        'splat--discount': discount,
        'splat--discount-indicative':
          priceType === SHOW_PRICE_TYPES.showDiscountIndicative,
        'splat--price': priceType !== SHOW_PRICE_TYPES.showDiscountIndicative,
        'splat--visible': visible,
        'splat-with-coin-flip': coinFlipAnimation,
      })}
    >
      <div className="splat__text">
        <p className="splat__now">
          {splatPriceABTest({ deal }) || displayDiscountAndOriginalPrice
            ? 'SAVE'
            : deal?.priceText?.toUpperCase()}
        </p>
        {isMarketingCreditDeal && (
          <p
            className="splat__vip"
            dangerouslySetInnerHTML={{
              __html: showPrice({
                currency: deal.currency,
                dealPrice:
                  splatPriceABTest({ deal }) || displayDiscountAndOriginalPrice
                    ? defaultPrice
                    : deal.vipPrice,
                forceDecimals: true,
                hideCurrency:
                  splatPriceABTest({ deal }) || displayDiscountAndOriginalPrice,
                pricePerPerson: false,
              }),
            }}
          />
        )}
        {showPerNight({ deal, priceType }) && (
          <p className="splat__per-night">Per Night</p>
        )}
        {/*         {priceType === SHOW_PRICE_TYPES.showDiscountIndicative && (
          <p className="splat__upto">{UP_TO}</p>
        )} */}
        {!isMobile && displayEstimatedText && (
          <p
            className="splat__adminfee"
            dangerouslySetInnerHTML={{
              __html: estimatedText || null,
            }}
          />
        )}
        {!splatPriceABTest({ deal }) &&
          !displayDiscountAndOriginalPrice &&
          !splatPriceDisplayHideNonVip({ deal }) && (
            <p
              className={classNames(
                {
                  'splat__price--bold':
                    (!isMarketingCreditDeal && isVipExclusiveDeal) ||
                    (!isMarketingCreditDeal && !isVipExclusiveDeal),
                  'splat__price--small': shrinkPrice,
                },
                'splat__price',
              )}
              dangerouslySetInnerHTML={{
                __html: showPrice({
                  currency: deal.currency,
                  dealPrice: deal.price,
                  forceDecimals: true,
                  pricePerPerson: deal.pricePerPerson,
                }),
              }}
              ref={priceContainer}
            />
          )}
        {!deal.price !== deal.originalPrice &&
          (!splatPriceABTest({ deal }) || displayDiscountAndOriginalPrice) && (
            <p
              className="splat__original-price"
              dangerouslySetInnerHTML={{
                __html: showPrice({
                  currency: deal.currency,
                  dealPrice: deal.originalPrice,
                  forceDecimals: true,
                  pricePerPerson: false,
                }),
              }}
            />
          )}
        {isMobile && displayEstimatedText && (
          <p
            className="splat__vip-adminfee"
            dangerouslySetInnerHTML={{
              __html: estimatedText || null,
            }}
          />
        )}
      </div>
      <style jsx>{`
        @keyframes coinflip {
          0% {
            transform: rotateY(270deg);
          }

          100% {
            transform: rotateY(360deg);
          }
        }
        .splat {
          width: 95px;
          height: 120px;
          overflow: hidden;
          position: absolute;
          bottom: 0;
          right: 0;
          background: url(${theme.images.dealvipsplat}) no-repeat;
          background-position: 10px 30px;
          background-size: 125px;
          z-index: 100;
          cursor: pointer;
          visibility: hidden;
          pointer-events: none;
          color: black;
          display: block;
        }

        .splat-with-coin-flip {
          animation: coinflip 0.3s ease-out;
        }

        .splat--visible {
          visibility: visible;
        }

        .splat__text {
          z-index: 999;
          position: absolute;
          bottom: 5px;
          right: 3px;
          color: #ffffff;
          text-align: center;
          line-height: 1em;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 54px;
          min-height: 38px;
          font-size: 17px;
          color: black;
        }
        .splat__now {
          font-size: 0.7em;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 1em;
          margin: 0;
        }
        .splat__price {
          font-size: 0.9em;
          line-height: 1em;
          margin: 0;
          position: relative;
        }

        .splat__per-night {
          font-size: 9px;
          line-height: 8px;
          margin: 0;
        }

        .splat__price:before {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          right: 0;
          width: 120%;
          margin-left: -10%;
          border-top: 1px solid;
          -webkit-transform: rotate(-11deg);
          -ms-transform: rotate(-11deg);
          transform: rotate(-11deg);
          display: ${deal.display?.vipDiscountAllowed ? 'block' : 'none'};
        }

        .splat__price--bold {
          font-size: 24px;
          font-weight: 600;
        }

        .splat__text:has(.splat__price--bold) {
          bottom: 12px;
        }

        .splat__vip {
          font-size: 1.1em;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 1em;
          margin: 0;
        }

        .splat__upto {
          font-size: 0.5em;
          text-transform: uppercase;
          line-height: 1em;
          margin: 0;
        }

         .splat__vip-adminfee {
          font-size: ${isMobile ? '0.4em' : '0.5em'};
          line-height: 1em;
          margin: 0;
          margin-top: ${isMobile ? '1em' : '0'};
          margin-right: ${isMobile ? '10px' : '0'};
        }

        :global(.cols-50 .splat__price--small) {
          font-size: 16px;
        }

        .xs .splat__text .splat__price--small {
          font-size: 14px;
        }
        .xs .splat__text .splat__price--bold {
          font-size: 16px;
        }

        .splat__original-price {
          position: relative;
          font-size: 0.6em;
          line-height: 1em;
          margin: 0;
        }

        .splat__original-price:before {
          position: absolute;
          content: '';
          left: 0;
          top: 50%;
          right: 0;
          width: 120%;
          margin-left: -10%;
          border-top: 1px solid;
          -webkit-transform: rotate(-11deg);
          -ms-transform: rotate(-11deg);
          transform: rotate(-11deg);
        }

        .splat :global(.price__cents) {
          font-size: 0.6em;
          line-height: 0;
        }

        .splat :global(.price__pp) {
          font-size: 0.5em;
          font-weight: normal;
          vertical-align: super;
          line-height: 0;
        }

        @media (min-width: ${theme.breakpoints.smUp}) {
          .splat {
            width: 90px;
            height: 110px;
            background-position: ${
              splatPriceABTest({
                deal,
              }) ||
              displayDiscountAndOriginalPrice ||
              splatPriceDisplayHideNonVip({ deal })
                ? ''
                : '10px 12px;'
            } 
            background-size: 115px;
          }

          .splat__text {
            font-size: 19px;
            min-height: 45px;
          }

          .splat__adminfee {
            font-size: 11px;
            margin-bottom: 2px;
          }
          .top-right {
            top: 0;
            bottom: initial;
            background-position: 10px -28px;
          }
          .top-right .splat__text {
            top: -30px;
            right: 4px;
            min-height: 51px;
          }

          .xs {
            background-position: 22px -18px;
            background-size: 90px;
          }
          .xs .splat__text {
            top: -38px;
            font-size: 16px;
            min-width: 48px;
            min-height: 48px;
          }
          .top-right.xs {
            background-position: 22px -18px;
          }
          .top-right.xs .splat__text {
            min-height: 48px;
          }

          .xl {
            width: 120px;
            height: 120px;
            background-position: 10px 12px;
            background-size: 140px;
          }
          .xl .splat__text {
            font-size: 24px;
            min-height: 70px;
            min-width: 80px;
          }
        }

        .main-deal--mobile {
          top: 0;
          bottom: initial;
          background-size: 110px;
          background-position: 0px -30px;
        }
        .main-deal--mobile .splat__text {
          top: 2px;
          bottom: auto;
          min-height: 60px;
          min-width: 60px;
          font-size: 20px;
          margin-right: -10px;
        }
        .main-deal--mobile--product-page {
          bottom: initial;
          background-size: 110px;
          background-position: 0px -30px;
        }
        .main-deal--mobile--product-page .splat__text {
          top: 2px;
          bottom: auto;
          min-height: 60px;
          min-width: 60px;
          font-size: 20px;
        }

        .full {
          width: 160px;
          height: 160px;
          background-size: contain;
          background-position: 0 0;
          bottom: -10px;
        }
        .full .splat__text {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
        }
        .full .splat__vip {
          font-size: 32px;
        }
        .full .splat__price {
          font-size: 24px;
        }
        .full .splat__price--bold {
          font-size: 32px;
          font-weight: 600;
        }
        .full .splat__now {
          font-size: 0.8em;
        }
        .full .splat__upto {
          font-size: 0.7em;
        }
        .full .splat__original-price {
          font-size: 1em;
        }
      `}</style>
    </div>
  );
};

VipDealPriceSplat.propTypes = {
  className: PropTypes.string,
  coinFlipAnimation: PropTypes.bool,
  deal: PropTypes.object,
  discount: PropTypes.bool,
  displayDiscountAndOriginalPrice: PropTypes.bool,
  displayEstimatedText: PropTypes.bool,
  estimatedText: PropTypes.string,
  forcePrice: PropTypes.bool,
  isHideIfNoDiscount: PropTypes.bool,
  isHideSplat: PropTypes.bool,
  visible: PropTypes.bool,
};

export default VipDealPriceSplat;
